import React from 'react'
import hero from '../pages/assets/Heros.png'

const Banner = () => {
  return (
    <div className="bg-[#0096FF] px-4 py-8 rounded-3xl mx-auto max-w-7xl sm:py-12 lg:py-16">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="text-white font-bold mb-8 lg:mb-0">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl mb-4 font-sans">
            Low Cost <br/>
            High Quality Education
          </h1>
          <div className="flex mt-[3rem] mr-[17rem] ml-[10rem]">
            <a href="/subscription" className="bg-white  text-[#0096FF] font-bold py-2 px-4 rounded-lg mr-4 mb-4 lg:mb-0 lg:ml-[9rem] ml-[4rem] font-sans text-decoration-none">Get Your Membership</a>
          </div>
        </div>
        <div className="relative">
          <img src={hero} alt="Hero" className="max-w-[338px] h-auto" />
          <div className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl">
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"
              }}
            />
          </div>
          <div className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl">
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner