import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config';

const StartNowCourse = () => {
	const { id } = useParams();
	const [course, setCourse] = useState({});
	const [selectedVideo, setSelectedVideo] = useState(null);

	useEffect(() => {
		checkSession();
		fetchCourseDetails();
	}, [id]);

	const fetchCourseDetails = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/courses/assigned/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				const courseData = response.data.data;
				setCourse(courseData);
				if (courseData.curriculum && courseData.curriculum.length > 0) {
					const firstModule = courseData.curriculum[0];
					if (firstModule.contentVideoPairs && firstModule.contentVideoPairs.length > 0) {
						const firstVideo = firstModule.contentVideoPairs[0];
						setSelectedVideo({
							title: firstVideo.content,
							videoUrl: firstVideo.videoUrl
						});
					}
				}
				console.log(courseData);
			} else {
				handleNon200Response(response);
			}
		} catch (err) {
			if (err.response) {
				handleNon200Response(err.response);
			} else {
				toast.error('Unexpected Error!');
			}
		}
	};

	const handleDownloadMaterials = () => {
		course.courseMaterials.forEach((file, index) => {
			setTimeout(() => {
				const link = document.createElement('a');
				link.href = `${API_URL}/download/materials/${file.split('/').pop()}`;
				link.download = file.split('/').pop();
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}, index * 1000); // Delay each download by 1 second
		});
	};

	const handleVideoClick = (title, videoUrl) => {
		setSelectedVideo({ title, videoUrl });
	};

	const getEmbedUrl = (url) => {
		const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
		return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : url;
	};

	const embedUrl = selectedVideo ? getEmbedUrl(selectedVideo.videoUrl) : null;

	return (
		<>
			<Header />
			<div className="bg-gray-100 min-h-screen p-4 mt-[7rem]">
				<div className="bg-white rounded-lg shadow-md p-6 m-[2rem]">
					<div className="flex justify-between items-center mb-4">
						<h1 className="text-2xl font-bold" style={{ fontFamily: 'sans-serif' }}>{course.title}</h1>
						<div className="flex items-center">
							<button
								type="button"
								className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mr-[2rem]"
								onClick={handleDownloadMaterials}
							>
								Download Material
							</button>
						</div>
					</div>
					<div className="mb-[.5rem] mt-[4rem]" style={{ height: '13rem', background: 'lavender', borderRadius: '20px' }}>
						<h2 className="text-lg font-semibold mb-2" style={{ margin: '1rem', fontFamily: 'sans-serif', paddingTop: '1rem', paddingLeft: '2rem' }}>Overview</h2>
						<p className='m-[1rem]' style={{ fontFamily: 'sans-serif' }}>{course.description}</p>
					</div>
					<div className="mb-5" style={{ height: '13rem', background: 'lavender', borderRadius: '20px', marginBottom: '1rem' }}>
						<h2 className="text-lg font-semibold mb-2" style={{ margin: '2rem', fontFamily: 'sans-serif', paddingTop: '1rem', paddingLeft: '1rem' }}>Includes</h2>
						<ul className="list-disc list-inside m-[1rem]" style={{ fontFamily: 'sans-serif', }}>
							<li className='m-[1rem] ml-[2rem]'>On-demand Videos</li>
							<li className='m-[1rem] ml-[2rem]'>Login & Track your progress</li>
							<li className='m-[1rem] ml-[2rem]'>Full Lifetime accesses</li>
						</ul>
					</div>
					<div className="flex flex-wrap">
						<div className="w-full md:w-7/12 pr-4">
							<div>
								<h2 className="text-lg font-semibold mb-2" style={{ fontFamily: 'sans-serif' }}>
									{selectedVideo ? selectedVideo.title : 'Loading...'}
								</h2>
								<div className="relative">
									{selectedVideo && (
										<iframe
											width="100%"
											height="600"
											src={getEmbedUrl(selectedVideo.videoUrl)}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											referrerPolicy="strict-origin-when-cross-origin"
											allowFullScreen
										></iframe>
									)}
									{selectedVideo && (
										<p>If the video does not load, <a href={selectedVideo.videoUrl} target="_blank" rel="noopener noreferrer">click here to watch it on YouTube</a>.</p>
									)}
								</div>
							</div>
						</div>
						<div className="w-full md:w-5/12 pl-4">
							<div className="mb-5" style={{ height: '18rem', background: 'lavender', borderRadius: '20px', marginBottom: '1rem' }}>
								<h2 className="text-lg font-semibold mb-2" style={{ margin: '2rem', fontFamily: 'sans-serif', paddingTop: '1rem', paddingLeft: '1rem' }}>LECTURES</h2>
								<ul className="list-decimal list-inside m-[1rem] overflow-y-auto max-h-48" style={{ fontFamily: 'sans-serif' }}>
									{course.curriculum ? course.curriculum.map((module) => (
										<details key={module._id} className="mb-2">
											<summary className="font-semibold cursor-pointer">{module.title}</summary>
											<ul>
												{module.contentVideoPairs.map((pair) => (
													<li key={pair._id} className="ml-[2rem]">
														<a href="#" onClick={() => handleVideoClick(pair.content, pair.videoUrl)}>{pair.content}</a>
													</li>
												))}
											</ul>
										</details>
									)) : <p>No curriculum available.</p>}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StartNowCourse;