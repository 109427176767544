import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/logo.png';
import axios from 'axios';
import { API_URL } from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SignUp = () => {
	const navigate = useNavigate();
	const [passwordError, setPasswordError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handlePasswordChange = (event) => {
		const password = event.target.value;
		if (password.length < 8) {
			setPasswordError('Password must be at least 8 characters');
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password)) {
			setPasswordError('Password must include uppercase, lowercase, number, and special character');
		} else {
			setPasswordError('');
		}
	};

	const handleConfirmPasswordChange = (event) => {
		const confirmPassword = event.target.value;
		const password = document.getElementById('password').value;
		if (password !== confirmPassword) {
			setPasswordError('Passwords do not match');
		} else {
			setPasswordError('');
		}
	};

	const handleSignUp = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const data = {
			email: formData.get('email'),
			password: formData.get('password'),
			confirmPassword: formData.get('confirmPassword'),
			fullname: formData.get('fullname')
		};

		if (!data.email || !data.password || !data.confirmPassword) {
			toast.error('All fields are required');
			return;
		}

		// Check password validity
		handlePasswordChange({ target: { value: data.password } });
		if (passwordError) {
			toast.error(passwordError);
			return;
		}

		// Check if passwords match
		handleConfirmPasswordChange({ target: { value: data.confirmPassword } });
		if (passwordError) {
			toast.error(passwordError);
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/auth/signup`, data);
			toast.success('Sign Up successful', {
				onClose: () => navigate('/')
			});
		} catch (error) {
			console.error('Sign Up error:', error);
			toast.error('Sign Up failed: ' + error.response.data.message);
		}
	};

	return (
		<div className="flex h-screen bg-gray-100 justify-center items-center">
			<div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
				<img src={logo} alt="Logo" style={{ width: '20%', marginBottom: '2rem', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
				<h1 className="text-3xl font-semibold mb-4 text-center">Sign Up</h1>
				<form onSubmit={handleSignUp} className="space-y-6">
					<div>
						<label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
						<input type="text" id="name" name="fullname" required className="mt-1 p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300" />
					</div>
					<div>
						<label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
						<input type="email" id="email" name="email" required className="mt-1 p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300" />
					</div>
					<div>
						
						<label htmlFor="password" className="block text-sm font-medium text-gray-700">Password &nbsp; &nbsp; (Must Be 8 Character)</label>
						<div className="flex items-center mt-1">
							<input type={showPassword ? "text" : "password"} id="password" name="password" required className="p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300" onChange={handlePasswordChange} />
							<button type="button" onClick={() => setShowPassword(!showPassword)} className="ml-2 text-gray-700">
								<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
							</button>
						</div>
						<div className="text-sm text-red-500">{passwordError}</div>
					</div>
					<div>

						<label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">Confirm Password &nbsp; &nbsp; (Must Be 8 Character)</label>
						<div className="flex items-center mt-1">
							<input type={showConfirmPassword ? "text" : "password"} id="confirm-password" name="confirmPassword" required className="p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300" onChange={handleConfirmPasswordChange} />
							<button type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="ml-2 text-gray-700">
								<FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
							</button>
						</div>
					</div>
					<button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300">Sign Up</button>
				</form>
				<div className="mt-4 text-sm text-gray-600 text-center">
					<p>Already have an account? <a href="#" className="text-blue-600 hover:underline" onClick={() => navigate('/')}>Sign In</a></p>
				</div>
				<ToastContainer />
			</div>
		</div>
	);
};

export default SignUp;