import { useState, Fragment, useEffect } from "react";
import "../styles/style.css";
import "../styles/bootstrap-4.5.3.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLock, faPlayCircle, faPlay, faGlobe, faArrowRight, faCheck, faAngleRight, faAnglesRight, faHourglassEnd, faCertificate, } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlay, faFileAlt, faClock, faClipboard, } from "@fortawesome/free-regular-svg-icons";
import ZoomHeader from "../Layout/ZoomHeader";
import Header from "../Layout/Header";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { handleNon200Response, checkSession } from "./session";
import { API_URL } from "../config";
import { useCart } from "./CartContext";
import Footer from "../Layout/Footer";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const EventDetail = () => {

	const [active, setActive] = useState(`collapse1`);
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const onClick = (value) => {
		setActive((current) => (current === value ? "" : value));
	};
	const [event, setEvent] = useState({
		title: "",
		zoomClassDate: "",
		zoomClassTime: "",
		duration: "",
		subject: "",
		language: "",
		price: 0,
		description: "",
		courseImage: "",
	});
	const { id } = useParams();
	useEffect(() => {
		fetchCourse();
	}, [id]);

	const fetchCourse = async () => {
		const token = localStorage.getItem("token");
		try {
			const response = await axios.get(`${API_URL}/api/events/student/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.status === 200) {
				setEvent(response.data.data);
				console.log(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (err) {
			if (err.response) {
				handleNon200Response(err.response);
			} else {
				toast.error("Unexpected Error!");
			}
		}
	};

	const { setCartCount } = useCart();
	const handleCheckout = async (eventId) => {
		const token = localStorage.getItem("token");

		if (!token) {
			// Store the eventId temporarily
			localStorage.setItem('redirectAfterLogin', eventId);
			navigate('/');
			return;
		} else {
			// Start checkout process directly on the same page
			setLoading(true);
			try {
				const response = await axios.post(`${API_URL}/api/zoom/checkout/`, { eventId }, {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`
					}
				});
				if (response.status === 201) {
					window.location.href = response.data.approvalUrl; // Redirect user to PayPal in the same tab
				} else {
					handleNon200Response(response);
				}
			} catch (error) {
				if (error.response) {
					handleNon200Response(error.response);
				} else {
					toast.error('Unexpected Error!');
				}
			} finally {
				setLoading(false);
			}
		}
	};
	const navigate = useNavigate();

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	return (
		<>
			{localStorage.getItem("token") ? <Header /> : <ZoomHeader />}
			<section className="course-details-area pt-[176px] rpt-100">
				<div className="container">
					<div className="row large-gap">
						<div className="col-lg-8">
							<div className="course-details-content">
								<ToastContainer />
								<h3 className="font-sans md:font-semibold">{event.title}</h3>
								<ul className="author-date-enroll">
									<li>
										<i className="fas fa-calendar-alt" /> {event.zoomClassDate}
									</li>
									<li>
										<i className="fas fa-clock" /> {event.zoomClassTime}
									</li>

								</ul>
								<div className="image mb-35">
									<img
										src={`${API_URL}/${event.courseImage}`}
										alt="Course Details"
									/>
								</div>
								<p
									className="font-sans md:font-semibold"
									style={{ marginBottom: "4rem" }}
								>
									{event.description}
								</p>

							</div>
						</div>
						<div className="col-lg-4">
							<div className="course-sidebar rmt-75" style={{ width: "25rem" }}>
								<div className="widget widget-course-details wow fadeInUp delay-0-2s">
									<div className="price-off">
										<span className="price">{event.price}</span>
										{/* <span className="off">25% off</span> */}
									</div>
									<ul className="course-details-list mb-25">
										<li style={{ width: "21rem" }}>
											<FontAwesomeIcon
												icon={faClock}
												style={{ color: "blue" }}
												width={30}
											/>
											<span>Duration</span> <b>{event.duration}</b>
										</li>
										<li>
											<FontAwesomeIcon
												icon={faClipboard}
												style={{ color: "blue" }}
												width={30}
											/>
											<span>Subject</span> <b>{event.subject}</b>
										</li>
										<li>
											<FontAwesomeIcon
												icon={faGlobe}
												style={{ color: "blue" }}
												width={30}
											/>
											<span>Language</span> <b>{event.language}</b>
										</li>
									</ul>
									<div>
										<input type="checkbox" id="termsCheckbox" className="mr-2" onChange={(e) => setTermsAccepted(e.target.checked)} />
										<label htmlFor="termsCheckbox">I accept the <a href="https://events.educatetheworld.co.uk/terms-and-conditions" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></label>
									</div>
									<button
										onClick={() => {
											if (termsAccepted) {
												handleCheckout(event._id);
											} else {
												toast.error('Please accept the terms and conditions to proceed.');
											}
										}}
										className="select-none w-[21rem] rounded-lg bg-[#4a77ec] from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
									>
										{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Checkout'}
									</button>


								</div>

							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default EventDetail