import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Subscription = () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const status = urlParams.get('status');
		if (status === 'success') {
			toast.success('Subscription Successful!');
		} else if (status === 'cancel') {
			toast.error('Subscription Cancelled!');
		}
	}, []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const handleSubscribtion = async () => {
		setLoading(true);
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(`${API_URL}/api/subscribe/create`, {}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			});
			if (response.status === 201) {
				const approvalUrl = new URL(response.data.approvalUrl);
				if (approvalUrl.origin.includes('paypal.com')) {
					window.location.href = approvalUrl.href;  // This already opens the URL in the same tab
				} else {
					toast.error('Invalid redirect URL.');
				}
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);  // Set loading to false when checkout is complete or fails
		}
	};

	return (
		<>
			<Header />
			<section class="bg-white dark:bg-gray-900" style={{ marginTop: '11rem' }}>
				<div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
					<div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
						<h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Educate The World</h2>
						<p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">EDUCATE THE WORLD LLP offers premium subscription packages designed to provide the best results for students preparing for the UCAT exam. These subscription plans offer a comprehensive suite of resources and support to ensure thorough preparation and maximize your chances of success.</p>
					</div>
					<div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">

						<div class="flex flex-col p-6  max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white" style={{ marginLeft: '11rem', width: '26rem' }}>
							<h3 class="mb-4 text-2xl font-semibold">Free Plan</h3>
							<div class="flex justify-center items-baseline my-8">
								<span class="mr-2 text-5xl font-extrabold">£0</span>
								<span class="text-gray-500 dark:text-gray-400">/Yearly</span>
							</div>

							<ul role="list" class="mb-8 space-y-4 text-left">
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Access to link to essential study materials</span>
								</li>
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Test Results sent to your Student Dashboard </span>
								</li>
								
							</ul>
							<button class="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white dark:focus:ring-green-900">Current Plan</button>
						</div>

						<div class="flex flex-col p-6  max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white" style={{ marginLeft: '21rem', width: '25rem' }}>
							<h3 class="mb-4 text-2xl font-semibold">Executive</h3>
							<div class="flex justify-center items-baseline my-8">
								<span class="mr-2 text-5xl font-extrabold">£50</span>
								<span class="text-gray-500 dark:text-gray-400">/Yearly</span>
							</div>

							<ul role="list" class="mb-8 space-y-4 text-left">
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>All past videos for free</span>
								</li>
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Support with any Personal Queries (reply within 72 hours)</span>
								</li>
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Study Material <span class="font-semibold">Free E-Book 'UCAT Sample Questions'</span></span>
								</li>
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Study Material <span class="font-semibold">Free E-Book 'MMI Practice Questions'</span></span>
								</li>
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Live Classes: <span class="font-semibold">5% off our Online Live Workshops</span></span>
								</li>
								<li class="flex items-center space-x-3">

									<svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
									<span>Quiz: <span class="font-semibold">⁠Free Mock UCAT Timed Practice</span></span>
								</li>
								
							</ul>
							<button onClick={handleSubscribtion} class="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white dark:focus:ring-primary-900">
								{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Subscribe'}
							</button>
						</div>


					</div>
				</div>
				<ToastContainer />
			</section>
			<Footer />
		</>
	)
}

export default Subscription