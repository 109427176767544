import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faKey, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser } from '@fortawesome/free-regular-svg-icons'
import { Player } from '@lottiefiles/react-lottie-player'
import { useNavigate } from 'react-router-dom'
import Header from '../Layout/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import SideNav from '../Layout/SideNav';

const PaymentHistory = () => {
	const navigate = useNavigate();
	useEffect(() => {
		checkSession();
	}, []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [paymentHistory, setPaymentHistory] = useState([]);
	const fetchPaymentHistory = async () => {
		setLoading(true);


		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/checkout/history`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header

				}
			});
			if (response.status === 200) {
				setPaymentHistory(response.data.data);
				console.log(response.data.data)
			} else {
				handleNon200Response(response);

			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchPaymentHistory();
	}, []);
	return (
		<>
			<Header />
			<section>
				<div className="course-timeline-wrap " style={{ marginTop: '15rem' }} />
				<SideNav />
				<section className="pt-[10rem] md:pt-[0rem] pb-[6rem] md:pb-[0rem] relative">
					<div class="w-full max-w-[75rem]  md:px-5 lg-6 mx-auto relative bottom-[76px] md:bottom-[695px] pl-[0.5rem] md:pl-[24.5rem] lg:max-w-[100rem]" >
						<h2 class="font-sans font-semibold text-4xl leading-10 text-black text-center " style={{ height: '100px' }}>
							Payment History
						</h2>

						<div class="block bg-white border shadow border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full">


							<div class="relative overflow-x-auto m-[1rem]">
								<table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
									<thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
										<tr>
											<th scope="col" class="px-6 py-3">
												SL No
											</th>
											<th scope="col" class="px-6 py-3">
												Order Id
											</th>
											<th scope="col" class="px-6 py-3">
												Payment Status
											</th>
											<th scope="col" class="px-6 py-3">
												Total Price
											</th>
											<th scope="col" class="px-6 py-3">
												Payment Date
											</th>
											<th scope="col" class="px-6 py-3">
												Invoice
											</th>
											<th scope="col" class="px-6 py-3">
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{paymentHistory.length > 0 ? (
											paymentHistory.map((payment, index) => (
												<tr key={payment._id} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
													<td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
														{index + 1}
													</td>
													<td class="px-6 py-4">
														{payment.orderId}
													</td>
													<td class={`px-6 py-4 ${payment.paymentStatus === 'Successful' ? 'text-green-500' : 'text-red-500'}`}>
														{payment.paymentStatus}
													</td>
													<td class="px-6 py-4">
														£{payment.totalAmount}
													</td>
													<td class="px-6 py-4">
														{new Date(payment.orderDate).toLocaleDateString()}
													</td>
													<td class="px-6 py-4">
														<a href={`${API_URL}${payment.invoiceFile}`} target="_blank" class="text-blue-600 hover:text-blue-900">
															Download Invoice
														</a>
													</td>
													<td class="px-6 py-4">
														<button
															class="text-blue-600 hover:text-blue-900"
															onClick={() => navigate(`/paymentDetails/${payment._id}`)}
														>
															View Details
														</button>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="6" class="px-6 py-4 text-center text-gray-500">
													No payment history available.
												</td>
											</tr>
										)}

									</tbody>
								</table>
							</div>

						</div>
					</div>
				</section>

			</section>
		</>
	)
}

export default PaymentHistory