import React, { useState, useEffect } from 'react';
import Logo2 from '../pages/assets/logo2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faKey, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser } from '@fortawesome/free-regular-svg-icons'
import { Player } from '@lottiefiles/react-lottie-player'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from '../pages/session';
import { API_URL } from '../config'


const SideNav = () => {
	const [userProfile, setUserProfile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const fetchUserProfile = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/student/profile`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setUserProfile(response.data.data);
				console.log(response.data.data);
				//toast.success('Banners fetched successfully!');
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchUserProfile();
	}, []);
	return (
		<a href="#" class="block max-w-[18rem] p-[1.5rem] bg-white border border-gray-200 rounded-[1.3rem] shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700" style={{ marginLeft: '69px', marginTop: '1px', textDecoration: 'none', height: '44rem' }}>
			<Player src='https://lottie.host/896b7ddc-12f7-49fb-a16f-569d522c71ae/qQQTslNxUD.json' autoplay loop style={{ height: '90px' }}></Player>
			<h3 className='font-sans md:font-semibold flex justify-center align-middle' style={{ width: '254px', color: 'black', position: 'relative', top: '9px', height: '32px', fontSize: '21px' }}>{userProfile ? userProfile.fullname : 'Guest'}</h3>
			<span className='font-sans flex justify-center align-middle' style={{ width: '252px', color: 'gray', fontWeight: '500', fontSize: '12px', height: '4px' }}>{userProfile ? userProfile.email : 'student@example.com'}</span>
			<ul class="space-y-4 text-left text-gray-500 dark:text-gray-400 font-sans md:font-semibold flex justify-center flex-col align-middle" style={{ width: '276px', padding: '43px 29px' }}>
				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faBookOpen} style={{ color: 'gray', width: '30px' }} />
					<a className='font-semibold text-gray-900 dark:text-white' style={{ padding: '10px', textDecoration: 'none' }} href='/Mycourse'>My Course</a>
				</li>
				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faCubes} style={{ color: 'black', width: '30px', width: '30px' }} />
					<a className='font-semibold text-gray-900 dark:text-white' style={{ padding: '10px', textDecoration: 'none' }}>Course Bundles</a>
				</li>

				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faCalendarCheck} style={{ color: 'black', width: '30px' }} />
					<a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/BookedTution'>Live Classes</a>
				</li>
				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faClockRotateLeft} style={{ color: 'black', width: '30px' }} />
					<a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/Payment'>Purchase history</a>
				</li>
				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faClockRotateLeft} style={{ color: 'black', width: '30px' }} />
					<a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/zoom-payment'>Zoom Purchase history</a>
				</li>
				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faUser} style={{ color: 'black', width: '30px' }} />
					<a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/profile'>Profile</a>
				</li>
				<li class="flex items-center space-x-3 rtl:space-x-reverse">
					<FontAwesomeIcon icon={faKey} style={{ color: 'black', width: '30px' }} />
					<a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/accountSetting'>Account</a>
				</li>
			</ul>
		</a>
	);
};

export default SideNav;

