import React, { useState, useEffect } from 'react';
import Logo2 from '../pages/assets/logo.png';
import axios from 'axios';
import { handleNon200Response } from '../pages/session';
import { API_URL } from '../config';
import { useCart } from '../pages/CartContext';

const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [userProfile, setUserProfile] = useState(null);
	const [userSubscription, setUserSubscription] = useState(null);
	const { cartCount, setCartCount } = useCart();

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const fetchUserProfile = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/api/student/profile`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				setUserProfile(response.data.data);
				setCartCount(response.data.data.cartCount);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			handleNon200Response(error.response || error);
		}
	};

	const fetchUserSubscription = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await axios.get(`${API_URL}/api/subscribe/check-subscription`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				setUserSubscription(response.data);
				console.log(response.data);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			handleNon200Response(error.response || error);
		}
	};

	useEffect(() => {
		fetchUserProfile();
		fetchUserSubscription();
	}, []);
	const logout = () => {
		if (window.confirm("Are you sure you want to log out of your session?")) {
			localStorage.removeItem('token');
			window.location.href = '/';
		}
	};

	return (
		<nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-lg">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex justify-between items-center h-16">
					<div className="flex-shrink-0">
						<a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
							<img src={Logo2} className="h-12" alt="Logo" />
						</a>
					</div>
					<div className="subscription-status flex items-center">
						{userSubscription && userSubscription.isSubscribed ? (
							<p className="text-green-500">Subscribed</p>
						) : (
							<p className="text-red-500">Not Subscribed</p>
						)}
					</div>
					<div className="hidden md:flex items-center space-x-4 flex-grow justify-center" style={{ marginLeft: '11rem', gap: "3rem" }}>
						<a
							href="/dashboard"
							className="text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
						>
							Home
						</a>
						<a
							href="/mycourse"
							className="text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
						>
							Dashboard
						</a>
						<a
							href="/cart"
							className="relative text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
						>
							Cart
							{cartCount > 0 && (
								<sup className="absolute -top-2 -right-2 bg-red-500 text-white px-2 py-1 rounded-full text-xs">{cartCount}</sup>
							)}
						</a>
						<a
							href="/subscription"
							className="text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
						>
							Membership
						</a>
						<a
							href="/events"
							className="text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
						>
							Events
						</a>
						<button
							onClick={logout}
							type="button"
							className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						>
							Logout
						</button>
						<button
							className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						>
							{userProfile ? `Welcome ${userProfile.fullname}` : 'Welcome Guest'}
						</button>
					</div>
					<div className="-mr-2 flex md:hidden">
						<button
							onClick={toggleMenu}
							type="button"
							className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
							aria-controls="mobile-menu"
							aria-expanded={isMenuOpen}
						>
							<span className="sr-only">Open main menu</span>
							{isMenuOpen ? (
								<svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							) : (
								<svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
								</svg>
							)}
						</button>
					</div>
				</div>
			</div>

			<div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
				<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
					<button className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-white">
						{userProfile ? `Welcome ${userProfile.fullname}` : 'Welcome Guest'}
					</button>
					<a href="/dashboard" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700">
						Home
					</a>
					<a href="/mycourse" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700">
						Dashboard
					</a>
					<a href="/cart" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700">
						Cart <sup style={{ color: 'red' }}>{cartCount}</sup>
					</a>
					<a href="/subscription" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700">
						Membership
					</a>
					<a href="/events" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-white hover:bg-gray-700 dark:text-white dark:hover:bg-gray-700">
						Events
					</a>
					<button
						onClick={logout}
						type="button"
						className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
					>
						Logout
					</button>
				</div>
			</div>
		</nav>
	);
};

export default Header;
