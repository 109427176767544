import { useState, useEffect } from 'react';
import ZoomHeader from '../Layout/ZoomHeader';
import ZoomTestimonials from './ZoomTestimonials'
import ZoomEvents from './ZoomEvents'
import Footer from '../Layout/Footer'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'

const ZoomPage = () => {




	useGSAP(() => {
		gsap.to('#home', { opacity: 1, delay: 1.5 })
		gsap.to('#logo', { opacity: 1, delay: 1.5 })
		gsap.to('#welcome', { opacity: 1, delay: 1.5 })
		gsap.to('#name', { opacity: 1, delay: 1.5 })
		gsap.to('#educate', { opacity: 1, delay: 1.5 })
	}, [])



	return (
		<>
			{/* <ZoomHeader /> */}
			<ZoomTestimonials />
			<ZoomEvents />
			<Footer />
		</>
	)
}
export default ZoomPage
