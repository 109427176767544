import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Working from './assets/working.jpg'
import Logo from './assets/logo.png'
import Header from '../Layout/Header';
import Avatar from './assets/avatar.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faBoxArchive, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faHeadset, faKey, faMoneyBill1, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser } from '@fortawesome/free-regular-svg-icons'
import { Modal } from 'react-bootstrap';
import SideNav from '../Layout/SideNav';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookedTution = () => {
	const [showModal, setShowModal] = useState(false);
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const status = urlParams.get('status');
		if (status === 'success') {
			toast.success('Payment Successful!');
		} else if (status === 'cancel') {
			toast.error('Payment Cancelled!');
		}
	}, []);
	useEffect(() => {
		checkSession();
	}, []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [assignedEvents, setAssignedEvents] = useState([]);
	const fetchAssignedCourses = async () => {
		setLoading(true);


		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/zoom/assigned-events`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header

				}
			});
			if (response.status === 200) {
				setAssignedEvents(response.data.data);
				console.log(response.data.data)
			} else {
				handleNon200Response(response);

			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	const navigate = useNavigate();
	useEffect(() => {
		fetchAssignedCourses();
	}, []);
	return (
		<>
			<Header />

			<section>
				<div className="course-timeline-wrap " style={{ marginTop: '16rem' }} />

				<h4 className='font-sans text-center h-[100px]' style={{ fontWeight: '500', fontSize: "29px" }}>Live Classes</h4>

				<SideNav />
				<div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-1rem md:mt-[-76.5rem] p-1rem md:p-[25rem] lg:w-[114rem] lg:ml-[-36px]" style={{ height: '80rem', }}>
					{/* Check if assignedCourses is an array and has data */}
					{Array.isArray(assignedEvents) && assignedEvents.length > 0 ? (
						assignedEvents.map((event) => (
							<div key={event._id} className="p-4 max-w-sm">
								<div key={event._id} class="p-4 max-w-sm">
									<div class="block max-w-sm p-0 bg-white border border-gray-200 rounded-[1.3rem] shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ">
										<div class="flex items-center mb-3">
											<img src={`${API_URL}/${event.courseImage}`} alt={event.title} style={{ borderRadius: '7px', cursor: 'pointer' }} />
										</div>
										<div class="flex flex-col justify-between flex-grow" style={{
											padding: '10px 30px',
											height: '114px'
										}}>
											<p class="leading-relaxed text-base text-black dark:text-black font-sans md:font-semibold" style={{ height: '49px' }}>
												{event.title}
											</p>
											<span className='font-sans md:font-semibold' style={{ color: 'gray', fontSize: '13px' }}>By {event.author} in {event.subject}</span>
											<div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700" style={{ marginTop: '25px' }}>
												<div class="bg-blue-600 h-2.5 rounded-full" style={{ width: '45%' }}></div>
											</div>
										</div>
										<button type="button" class="text-white bg-[#007dc6] hover:bg-[#488db8] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 mx-auto dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " style={{ marginTop: '20px', width: '100%' }} onClick={() => setShowModal(true)}>
											<FontAwesomeIcon icon={faCirclePlay} style={{ width: '24px' }} />
											Start Now
										</button>
										{showModal && (
											<Modal show={showModal} onHide={() => setShowModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
												<Modal.Header closeButton>
													<Modal.Title id="contained-modal-title-vcenter">
														Zoom Meeting
													</Modal.Title>
												</Modal.Header>
												<Modal.Body>
													<iframe src={event.zoomLink} style={{ width: '100%', height: '500px' }} allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>
												</Modal.Body>
											</Modal>
										)}
									</div>
								</div>
							</div>
						))
					) : (
						<p className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60rem', height: '50rem' }}>No Live Classes assigned.</p>
					)}
				</div>
				<ToastContainer />
			</section>
		</>
	)
}

export default BookedTution