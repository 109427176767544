import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import Work from './assets/working.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config';
import './Benifits.css';

const Benifits = () => {
	useEffect(() => {
		checkSession();
	}, []);

	const [banners, setBanners] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchBanners = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/banners/list/students`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setBanners(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchBanners();
	}, []);

	return (
		<div className="mt-8">
			<div style={{ height: '50px' }}></div>
			<Splide aria-label="My Favorite Images" className='splide' options={{
				type: 'loop', gap: '1px', pagination: false, arrows: false, drag: 'free', AutoScroll: {
					pauseOnHover: false,
					pauseOnFocus: false,
					rewind: true,
					speed: 2,
				}
			}} extensions={{ AutoScroll }}>
				{banners.map((banner) => (
					<SplideSlide key={banner._id}>
						<img src={`${API_URL}/${banner.file}`} alt={`Banner ${banner._id}`} className='splide__slide-img' />
					</SplideSlide>
				))}
			</Splide>
			<div style={{ height: '50px' }}></div>
		</div>
	);
}

export default Benifits;