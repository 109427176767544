import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faKey, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser } from '@fortawesome/free-regular-svg-icons'
import { Player } from '@lottiefiles/react-lottie-player'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../Layout/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import SideNav from '../Layout/SideNav';

const PaymentHistory = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	useEffect(() => {
		checkSession();
	}, []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [paymentDetails, setPaymentDetails] = useState([]);

	const fetchPaymentDetails = async () => {

		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/checkout/history/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header

				}
			});
			if (response.status === 200) {
				setPaymentDetails(response.data.data);
				console.log(response.data.data)
			} else {
				handleNon200Response(response);

			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchPaymentDetails();
	}, []);
	return (
		<>
			<Header />
			<section>

				<div className="course-timeline-wrap " style={{ marginTop: '15rem' }} />
				<SideNav />
				<section className="pt-[10rem] md:pt-[13rem] pb-[6rem] md:pb-[6rem] relative">

					<div class="w-full max-w-[75rem]  md:px-5 lg-6 mx-auto relative bottom-[76px] md:bottom-[1080px] pl-[0.5rem] md:pl-[24.5rem] lg:max-w-[100rem]" >
						<h2 class="font-sans font-semibold text-4xl leading-10 text-black text-center " style={{ height: '100px' }}>
							Payment History
						</h2>

						<div class="block bg-white border shadow border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full">


							<div class="relative overflow-x-auto m-[1rem]">
								<div className="p-4">
									<h3 className="text-lg font-semibold mb-2">Payment Details</h3>
									<div className="bg-gray-100 p-4 rounded-lg">
										<p><strong>Order ID:</strong> {paymentDetails.orderId}</p>
										<p><strong>PayPal Order ID:</strong> {paymentDetails.paypalOrderId}</p>
										<p><strong>Payment Status:</strong> {paymentDetails.paymentStatus}</p>
										<p><strong>Total Amount:</strong> £{paymentDetails.totalAmount}</p>
										<p><strong>Order Date:</strong> {new Date(paymentDetails.orderDate).toLocaleDateString()}</p>
										<div className="mt-4">
											<h4 className="text-md font-semibold mb-2">Course Details</h4>
											<table className="min-w-full divide-y divide-gray-200">
												<thead className="bg-gray-50">
													<tr>
														<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
															Title
														</th>
														<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
															Price
														</th>
													</tr>
												</thead>
												<tbody className="bg-white divide-y divide-gray-200">
													{paymentDetails.courseIds ? paymentDetails.courseIds.map((course, index) => (
														<tr key={index}>
															<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
																{course.title}
															</td>
															<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																£{course.discountPrice}
															</td>
														</tr>
													)) : (
														<tr>
															<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" colSpan="2">
																No course details available.
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
				</section>

			</section>
		</>
	)
}

export default PaymentHistory