import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL } from '../config';
import { handleNon200Response, checkSession } from "./session";
import logo from './assets/logo.png';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!email) {
			toast.error('Please enter your email address.');
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/api/user/forgot-password`, { email });
			if (response.status === 200) {  // Assuming 200 is the success status code for this API
				if (response.data.success) {
					toast.success('Password reset link sent! Check your email.');
				} else {
					toast.error(response.data.message);
				}
			} else {
				handleNon200Response(response);  // Handle non-200 responses generically
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);  // Use the same error handling as in fetchCourse
			} else {
				toast.error('An error occurred while sending the password reset email.');
			}
		}
	};

	return (
		<div className="flex h-screen bg-gray-100 justify-center items-center">
			<div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
				<img src={logo} alt="Logo" style={{ width: '20%', marginBottom: '2rem', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
				<h1 className="text-3xl font-semibold mb-4 text-center">Forgot Password?</h1>
				<form onSubmit={handleSubmit} className="space-y-6">
					<div>
						<label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
						<input
							type="email"
							id="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="mt-1 p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300"
						/>
					</div>
					<button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300">Send Reset Link</button>
				</form>
				<ToastContainer />
			</div>
		</div>
	);
};

export default ForgotPassword;
