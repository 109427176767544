import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config';

const Cards = () => {
	const navigate = useNavigate();

	useEffect(() => {
		checkSession();
	}, []);

	const [courses, setCourses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [coursesPerPage] = useState(8);

	const fetchCourses = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/courses/list/students`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setCourses(response.data.data);
				console.log(courses);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCourses();
	}, []);

	// Pagination logic
	const indexOfLastCourse = currentPage * coursesPerPage;
	const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
	const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

	const paginate = pageNumber => setCurrentPage(pageNumber);

	return (
		<>
			<h4 className="ml-6 lg:ml-[12rem] xl:ml-[12rem] mb-16 font-medium">Latest Courses</h4>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:mx-40 lg:mt-4 gap-4">
				{currentCourses.map(course => (
					<div key={course._id} className="p-4 max-w-sm">
						<div className="block max-w-sm p-0 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
							<div className="flex items-center mb-3">
								<img
									src={`${API_URL}/${course.courseImage}`}
									alt={course.title}
									className="w-full h-auto rounded-t-lg cursor-pointer"
									onClick={() => navigate(`/course/${course._id}`)} />
							</div>
							<div className="flex flex-col justify-between flex-grow px-4 py-4">
								<p className="text-lg font-semibold dark:text-white cursor-pointer" onClick={() => navigate(`/course/${course._id}`)}>
									{course.title}
								</p>
								<span className="text-base text-gray-500 dark:text-gray-400">By {course.author}</span>
							</div>
							<h5 className="text-lg font-semibold px-4 py-4">
								<strike>£{course.price}</strike> £{course.discountPrice}
							</h5>
						</div>
					</div>
				))}
			</div>
			<div className="flex justify-center mt-8">
				<ul className="flex items-center space-x-2 text-sm">
					<li>
						<button onClick={() => paginate(currentPage - 1)} className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}>
							&#9664;
						</button>
					</li>
					{Array.from({ length: Math.ceil(courses.length / coursesPerPage) }, (_, i) => (
						<li key={i + 1}>
							<button onClick={() => paginate(i + 1)} className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${currentPage === i + 1 ? 'bg-gray-200' : ''}`}>
								{i + 1}
							</button>
						</li>
					))}
					<li>
						<button onClick={() => paginate(currentPage + 1)} className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${currentPage === Math.ceil(courses.length / coursesPerPage) ? 'cursor-not-allowed opacity-50' : ''}`}>
							&#9654;
						</button>
					</li>
				</ul>
			</div>
		</>
	);
}

export default Cards;