import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './assets/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../config'

const ResetPassword = () => {
	const { token } = useParams();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleConfirmPasswordChange = (e) => {
		setConfirmPassword(e.target.value);
	};

	const handleShowPasswordToggle = () => {
		setShowPassword(!showPassword);
	};

	console.log(token);
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			toast.error('Passwords do not match');
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/api/user/reset-password/${token}`, JSON.stringify({ password, confirmPassword }), {
				headers: {
					'Content-Type': 'application/json'
				}
			});
			toast.success(response.data.message, {
				onClose: () => navigate('/')
			});
		} catch (error) {
			toast.error(error.response.data.message);
		}
	};

	return (
		<div className="flex h-screen bg-gray-100 justify-center items-center">
			<div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
				<img src={logo} alt="Logo" style={{ width: '20%', marginBottom: '2rem', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
				<h2 className="text-3xl font-semibold mb-4 text-center">Reset Password</h2>
				<form onSubmit={handleSubmit} className="space-y-6">
					<div>
						<label htmlFor="password" className="block text-sm font-medium text-gray-700">New Password</label>
						<input
							type={showPassword ? 'text' : 'password'}
							id="password"
							value={password}
							onChange={handlePasswordChange}
							required
							className="mt-1 p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300"
						/>
					</div>
					<div>
						<label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
						<input
							type={showPassword ? 'text' : 'password'}
							id="confirmPassword"
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
							required
							className="mt-1 p-2 w-full border rounded-md focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition-colors duration-300"
						/>
					</div>
					<div className="flex items-center">
						<input
							type="checkbox"
							id="showPassword"
							checked={showPassword}
							onChange={handleShowPasswordToggle}
							className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
						/>
						<label htmlFor="showPassword" className="ml-2 block text-sm text-gray-900">Show Password</label>
					</div>
					<button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300">Reset Password</button>
				</form>
			</div>
			<ToastContainer />
		</div>
	);
};

export default ResetPassword;
