import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import { useCart } from './CartContext';

const ZoomCart = () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const status = urlParams.get('status');
		if (status === 'success') {
			toast.success('Payment Successful!');
		} else if (status === 'cancel') {
			toast.error('Payment Cancelled!');
		}
	}, []);
	useEffect(() => {
		checkSession();
	}, []);
	const [cartItems, setCartItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const fetchCartItems = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/zoom/view/`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setCartItems(response.data.data.items);
				console.log(response.data.data.items)
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};


	const handleCheckout = async () => {
		setLoading(true);  // Set loading to true when checkout starts
		const token = localStorage.getItem('token');
		try {
			const response = await axios.post(`${API_URL}/api/zoom/checkout/`, {}, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			});
			if (response.status === 201) {
				window.location.href = response.data.approvalUrl; // Redirect user to PayPal in the same tab
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);  // Set loading to false when checkout is complete or fails
		}
	};
	const { setCartCount } = useCart();
	const handleRemoveItem = async (eventId) => {
		const token = localStorage.getItem('token');


		try {
			const response = await axios.delete(`${API_URL}/api/zoom/delete/${eventId}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 200) {
				toast.success(response.data.message);
				setCartCount(prevCount => prevCount - 1); // Decrement cart count
				fetchCartItems();
				setCartItems(prevItems => [...prevItems.filter(item => item.eventId !== eventId)]);
			} else {
				fetchCartItems();
				handleNon200Response(response);

			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		}
	};
	useEffect(() => {
		fetchCartItems();
	}, []);
	return (
		<>
			<Header />
			<section class="bg-white dark:bg-gray-900" style={{ marginTop: '11rem' }}>
				<div className="cart-container" style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto', display: 'flex', justifyContent: 'space-between' }}>
					<div className="cart-items" style={{ flex: 3 }}>
						{cartItems.length > 0 ? (
							<>
								<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
									{cartItems.map((item, index) => (
										<div key={item._id} className="cart-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
											<div className="item-details" style={{ display: 'flex', alignItems: 'center' }}>
												<img src={`${API_URL}/${item.courseImage}`} alt={item.title} className="item-image" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px', marginRight: '20px' }} />
												<div className="item-info">
													<h4 style={{ margin: '0 0 10px 0' }}>{item.title}</h4>
													<p style={{ margin: '0', color: '#888' }}>In Stock</p>
												</div>
											</div>
											<div className="item-price" style={{ fontSize: '18px', fontWeight: 'bold', color: '#4CAF50' }}>
												<p>£{item.price}</p>
											</div>
											<div className="item-remove">
												<button onClick={() => handleRemoveItem(item.id)} style={{ background: 'none', border: 'none', color: '#007BFF', cursor: 'pointer' }}>Remove</button>
											</div>
										</div>
									))}
								</div>
								<div style={{ height: '5%' }}></div>
								<div className="order-summary" style={{ flex: 1, marginTop: '0', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
									<h3 style={{ marginBottom: '20px' }}>Order Summary</h3>
									<p style={{ display: 'flex', justifyContent: 'space-between' }}>
										<span>Subtotal:</span>
										<span>£{cartItems.reduce((acc, item) => acc + item.price, 0)}</span>
									</p>

									<p style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '18px' }}>
										<span>Order total:</span>
										<span>£{cartItems.reduce((acc, item) => acc + item.price, 0)}</span>
									</p>
									<button onClick={handleCheckout} style={{ width: '100%', padding: '10px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '8px', cursor: 'pointer', fontSize: '16px' }}>
										{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Checkout'}
									</button>
								</div>
							</>
						) : (
							<section class="bg-white dark:bg-gray-900" style={{ marginTop: '11rem' }}>
								<p>Your cart is empty.</p>
							</section>
						)}
					</div>
					<ToastContainer />
				</div>
			</section>
			<Footer />
		</>
	);
};

export default ZoomCart;
