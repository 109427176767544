import { useState, useEffect } from 'react';

import Header from '../Layout/Header';
import Testimonials from './Testimonials'
import Cards from './Cards'
import Benifits from './Benifits'
import Footer from '../Layout/Footer'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'

const Dashboard = () => {




	useGSAP(() => {
		gsap.to('#home', { opacity: 1, delay: 1.5 })
		gsap.to('#logo', { opacity: 1, delay: 1.5 })
		gsap.to('#welcome', { opacity: 1, delay: 1.5 })
		gsap.to('#name', { opacity: 1, delay: 1.5 })
		gsap.to('#educate', { opacity: 1, delay: 1.5 })
	}, [])



	return (
		<>
			<Header />
			<Testimonials />
			<Cards />
			<Benifits />
			<Footer />
		</>
	)
}


export default Dashboard
