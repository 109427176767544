import { useState, useEffect } from 'react';
import Working from './assets/working.jpg'
import Logo from './assets/logo.png'
import Wordpress from './assets/wordpress.jpg'
import Avatar from './assets/avatar.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faKey, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser } from '@fortawesome/free-regular-svg-icons'
import Ux from './assets/ux.jpg'
import Photo from './assets/Photo.jpg'
import Video from './assets/video.jpg'
import Corporate from './assets/corporate.jpg'
import Language from './assets/language.jpg'
import { useNavigate } from 'react-router-dom'
import '../styles/style.css'
import { Player } from '@lottiefiles/react-lottie-player'
import Header from '../Layout/Header';
import Footer from '../Layout/Footer'
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import SideNav from '../Layout/SideNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyCourse = () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const status = urlParams.get('status');
		if (status === 'success') {
			toast.success('Payment Successful!');
		} else if (status === 'cancel') {
			toast.error('Payment Cancelled!');
		}
	}, []);
	useEffect(() => {
		checkSession();
	}, []);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [assignedCourses, setAssignedCourses] = useState([]);
	const fetchAssignedCourses = async () => {
		setLoading(true);


		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/courses/list/assigned`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header

				}
			});
			if (response.status === 200) {
				setAssignedCourses(response.data.data);
				console.log(response.data.data)
			} else {
				handleNon200Response(response);

			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	const navigate = useNavigate();
	useEffect(() => {
		fetchAssignedCourses();
	}, []);
	return (
		<>
			<Header />
			<section>
				<div className="course-timeline-wrap " style={{ marginTop: '16rem' }} />

				<h4 className='font-sans text-center h-[168px]' style={{ fontWeight: '500', fontSize: "29px" }}>Owned Courses</h4>

				<SideNav />
				<div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-1rem md:mt-[-72.5rem] p-1rem md:p-[27rem] lg:w-[124rem] lg:ml-[-36px]" style={{height: '80rem', }}>
					{/* Check if assignedCourses is an array and has data */}
					{Array.isArray(assignedCourses) && assignedCourses.length > 0 ? (
						assignedCourses.map((course) => (
							<div key={course._id} className="p-4 max-w-sm">
								<div key={course._id} class="p-4 max-w-sm">
									<div class="block max-w-sm p-0 bg-white border border-gray-200 rounded-[1.3rem] shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ">
										<div class="flex items-center mb-3">
											<img src={`${API_URL}/${course.courseImage}`} alt={course.title} style={{ borderRadius: '7px', cursor: 'pointer' }} onClick={() => navigate(`/startnow/${course._id}`)} />
										</div>
										<div class="flex flex-col justify-between flex-grow" style={{
											padding: '10px 30px',
											height: '114px'
										}}>
											<p class="leading-relaxed text-base text-black dark:text-black font-sans md:font-semibold" style={{ height: '49px' }}>
												{course.title}
											</p>
											<span className='font-sans md:font-semibold' style={{ color: 'gray', fontSize: '13px' }}>By {course.author} in {course.subject}</span>
											<div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700" style={{ marginTop: '25px' }}>
												<div class="bg-blue-600 h-2.5 rounded-full" style={{ width: '45%' }}></div>
											</div>
										</div>
										<div class="flex items-center" style={{ height: '38px', padding: '5px 28px', marginTop: '41px' }}>
											<svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
												<path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
											</svg>
											<svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
												<path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
											</svg>
											<svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
												<path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
											</svg>
											<svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
												<path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
											</svg>
											<svg class="w-4 h-4 text-gray-300 me-1 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
												<path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
											</svg>
											<p class="ms-1 text-sm font-sans md:font-semibold text-black dark:text-black " style={{ height: '2px' }}>4.8</p>
										</div>
										<button type="button" class="text-white bg-[#007dc6] hover:bg-[#488db8] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 mx-auto dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " style={{ marginTop: '20px', width: '100%' }} onClick={() => navigate(`/startnow/${course._id}`)}>
											<FontAwesomeIcon icon={faCirclePlay} style={{ width: '24px' }} />
											Start Now
										</button>
									</div>
								</div>
							</div>
						))
					) : (
						<p className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60rem', height: '50rem' }}>No courses assigned.</p>
					)}
				</div>
				<ToastContainer />
			</section>
		</>
	)
}

export default MyCourse