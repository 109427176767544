import React from 'react';
import { CartProvider } from './pages/CartContext';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import MyCourse from './pages/MyCourse';
import Wishlist from './pages/Wishlist';
import Login from './pages/Login';
import Testimonials from './pages/Testimonials';
import Cards from './pages/Cards';
import Benifits from './pages/Benifits';
import CourseOverview from './pages/CourseOverview';
import PaymentHistory from './pages/PaymentHistory';
import BookedTution from './pages/BookedTution';
import Messages from './pages/Messages';
import MyCertificates from './pages/MyCertificates';
import MyProfile from './pages/MyProfile';
import AccountSetting from './pages/AccountSetting';
import Subscription from './pages/Subscription';
import SignUp from './pages/SignUp';
import Cart from './pages/Cart';
import StartNowCourse from './pages/StartNowCourse';
import PaymentDetails from './pages/PaymentDetails';
import EventDetail from './pages/EventDetail';
import ZoomPage from './pages/ZoomPage';
import ZoomCart from './pages/ZoomCart';
import ZoomEventDetail from './pages/ZoomEventDetails';
import ZoomPayment from './pages/ZoomPayment';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

function App() {
	return (
		<CartProvider>
			<div className="">
				<Routes>
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/' element={<Login />} />
					<Route path='/signup' element={<SignUp />} />
					<Route path='/mycourse' element={<MyCourse />} />
					<Route path='/course/:id' element={<CourseOverview />} />
					<Route path='/wishlist' element={<Wishlist />} />
					<Route path='/payment' element={<PaymentHistory />} />
					<Route path='/bookedtution' element={<BookedTution />} />
					<Route path='/certificates' element={<MyCertificates />} />
					<Route path='/profile' element={<MyProfile />} />
					<Route path='/accountsetting' element={<AccountSetting />} />
					<Route path='/cart' element={<Cart />} />
					<Route path='/startnow/:id' element={<StartNowCourse />} />
					<Route path='/cards' element={<Cards />} />
					<Route path='/subscription' element={<Subscription />} />
					<Route path='/paymentDetails/:id' element={<PaymentDetails />} />
					<Route path='/eventDetail/:id' element={<EventDetail />} />
					<Route path='/events' element={<ZoomPage />} />
					<Route path='/zoom-cart' element={<ZoomCart />} />
					<Route path='/zoom-event-detail/:id' element={<ZoomEventDetail />} />
					<Route path='/zoom-payment' element={<ZoomPayment />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/reset-password/:token' element={<ResetPassword />} />
				</Routes>
			</div>
		</CartProvider>
	);
}

export default App;