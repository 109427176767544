import { useState, useEffect } from 'react';
import Word from './assets/word.png'
import './runningtext.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ZoomTestimonials = () => {

	return (
		<div className="container mx-auto px-4 md:px-6 my-24" style={{ maxWidth: '1400px' }}>
			<div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-[#7CB9E8] px-6 py-2.5 rounded-3xl mx-auto  mt-[13rem] sm:mt-0 md:mt-0 lg:mt-0 xl:mt-[100px] max-w-7xl">
				<div
					className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
					aria-hidden="true"
				>
					<div
						className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
						style={{
							clipPath:
								"polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"
						}}
					/>
				</div>
				<div
					className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
					aria-hidden="true"
				>
					<div
						className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
						style={{
							clipPath:
								"polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"
						}}
					/>
				</div>
				<div className="flex flex-wrap items-center gap-x-4 gap-y-2">
					<span className="text-sm leading-6 text-gray-900 bar_content">
						<strong className="font-semibold ">Welcome to Educate The World</strong>
						<svg
							viewBox="0 0 2 2"
							className="mx-2 inline h-0.5 w-0.5 fill-current"
							aria-hidden="true"
						>
							<circle cx={1} cy={1} r={1} />
						</svg>
					</span>
				</div>
				<div className="flex-1 justify-end hidden md:flex">
				</div>
			</div>
		</div>
	)
}

export default ZoomTestimonials