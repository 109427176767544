import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const SimpleAccordion = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div style={{ marginBottom: '10px', border: '1px solid #ddd', borderRadius: '4px', overflow: 'hidden' }}>
			<button onClick={toggleAccordion} style={{ width: '100%', textAlign: 'left', padding: '10px', fontSize: '18px', cursor: 'pointer', border: 'none', backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span style={{ fontStyle: 'italic' }}>{title}</span>
				<FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
			</button>
			{isOpen && (
				<div style={{ padding: '10px', borderTop: '1px solid #ddd' }}>
					{children}
				</div>
			)}
		</div>
	);
};

export default SimpleAccordion;