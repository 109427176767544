import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config';
import Header from "../Layout/Header";
import ZoomHeader from "../Layout/ZoomHeader";

const Cards = () => {
	const navigate = useNavigate();
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchCourses = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/events/list-public`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setEvents(response.data.data);
				console.log(events);
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCourses();
	}, []);

	return (
		<>
			{localStorage.getItem("token") ? <Header /> : <ZoomHeader />}
			<h4 className="ml-6 lg:ml-[12rem] xl:ml-[12rem] mb-16 font-medium">Zoom Classes</h4>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:mx-40 lg:mt-[-3rem] gap-4 " >
				{events.map(event => (
					<>
						<div key={event._id} className="p-4 max-w-sm">
							<div className="block max-w-sm p-0 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
								<div className="flex items-center mb-3">
									<img
										src={`${API_URL}/${event.courseImage}`}
										alt={event.title}
										className="w-full h-auto rounded-t-lg cursor-pointer"
										onClick={() => navigate(`/eventDetail/${event._id}`)} />
								</div>
								<div className="flex flex-col justify-between flex-grow px-4 py-4">
									<p className="text-lg font-semibold dark:text-white cursor-pointer" onClick={() => navigate(`/eventDetail/${event._id}`)}>
										{event.title}
									</p>
									<span className="text-base text-gray-500 dark:text-gray-400">Date {new Date(event.zoomClassDate).toLocaleDateString()}</span>
									<span className="text-base text-gray-500 dark:text-gray-400">Time {event.zoomClassTime}</span>
								</div>
								<h5 className="text-lg font-semibold px-4 py-4">
									<button onClick={() => navigate(`/eventDetail/${event._id}`)} className="text-white bg-blue-700 hover:bg-blue-8000 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enroll Now</button>
								</h5>
							</div>

						</div>

					</>

				))}
			</div>
		</>
	);
}

export default Cards;
