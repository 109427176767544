import React, { useState } from 'react'
import axios from 'axios';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faKey, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser } from '@fortawesome/free-regular-svg-icons'
import { Player } from '@lottiefiles/react-lottie-player'
import Header from '../Layout/Header'
import SideNav from '../Layout/SideNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleNon200Response, checkSession } from './session';
const MyProfile = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [newpassword, setNewpassword] = useState('');
	const [confirmnewpassword, setConfirmnewpassword] = useState('');


	const handleSubmit = async (e) => {
		if (!name || !email || !newpassword || !confirmnewpassword) {
			toast.error('All fields are required!');
			return;
		}
		if (newpassword !== confirmnewpassword) {
			toast.error('New passwords do not match!');
			return;
		}
		e.preventDefault();
		setLoading(true);
		setError(null);
		const userData = {
			name: name,
			email: email,
			newpassword: newpassword,
			confirmnewpassword: confirmnewpassword
		};
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.put(`${API_URL}/api/update/profile`, userData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			});
			if (response.status === 200) {
				toast.success('Profile Updated Successfully');
			} else {
				handleNon200Response(response);

			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Header />
			<section>
				<div className="course-timeline-wrap " style={{ marginTop: '15rem' }} />
				<SideNav />
				<div class="w-full max-w-[75rem]  md:px-5 lg-6 mx-auto relative bottom-[-36px] md:bottom-[795px] pl-[0.5rem] lg:pl-[24.5rem] lg:max-w-[100rem]" >
					<h2 class="font-sans font-semibold text-4xl leading-10 text-black text-center " style={{ height: '100px' }}>
						Profile
					</h2>
					<div class="block bg-white border shadow border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full  h-[48rem] md:h-[40rem]">
						<div
							class="flex flex-col lg:flex-row lg:items-center justify-between px-6 pb-6 border-b border-gray-200">
							<div class="data">
								<Player src='https://lottie.host/896b7ddc-12f7-49fb-a16f-569d522c71ae/qQQTslNxUD.json' autoplay loop style={{ height: '90px' }}></Player>
							</div>
							<h5>Update Profile Img</h5>
							<label for="uploadFile1"
								class="bg-[#007dc6] hover:bg-blue-600 text-white text-sm px-4 py-2.5 outline-none rounded w-max cursor-pointer  block font-[sans-serif] mr-[2rem]  ml-[7rem] mt-[1rem] md:mt-[0rem]">
								<svg xmlns="http://www.w3.org/2000/svg" class="w-5 mr-2 fill-white inline" viewBox="0 0 32 32">
									<path
										d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
										data-original="#000000" />
									<path
										d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
										data-original="#000000" />
								</svg>
								Upload
								<input type="file" id='uploadFile1' class="hidden" />
							</label>
						</div>
						<section>
							<h4 className='ml-[8.5rem] md:ml-[3rem] mt-[1rem] md:mt-[1rem]'>Profile Info</h4>

							<hr className='m-[rem] ' />
							<form class="max-w-sm mx-auto" style={{ width: '63rem', padding: '1rem' }} onSubmit={handleSubmit}>
								<label for="phone-input" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
								<div class="flex items-center mt-2">
									<button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{ height: '3rem' }}>
										<FontAwesomeIcon icon={faUser} style={{ width: '20px' }} />
									</button>
									<div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
									</div>
									<div class="relative w-full">
										<input type="text" name="name" id="phone-input" onChange={(e) => setName(e.target.value)} aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Name" required style={{ height: '3rem' }} />
									</div>
								</div>
								<label for="phone-input" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address:</label>
								<div class="flex items-center mt-2">
									<button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{ height: '3rem' }}>
										<FontAwesomeIcon icon={faUser} style={{ width: '20px' }} />
									</button>
									<div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">

									</div>
									<div class="relative w-full">
										<input type="text" name="email" id="phone-input" onChange={(e) => setEmail(e.target.value)} aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Email" required style={{ height: '3rem' }} />
									</div>
								</div>


								<label for="phone-input" class="mt-[1.5rem] text-sm font-medium text-gray-900 dark:text-white">New password:</label>
								<div class="flex items-center mb-[0rem]">
									<button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{ height: '3rem' }}>
										<FontAwesomeIcon icon={faKey} style={{ width: '20px', color: 'gray' }} />
									</button>
									<div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">

									</div>
									<div class="relative w-full">
										<input type="text" name="newpassword" id="phone-input" onChange={(e) => setNewpassword(e.target.value)} aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Enter your Password" required style={{ height: '3rem' }} />
									</div>
								</div>
								<label for="phone-input" class="mt-[1.5rem] text-sm font-medium text-gray-900 dark:text-white">Confirm password:</label>
								<div class="flex items-center mb-[0rem]">
									<button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{ height: '3rem' }}>
										<FontAwesomeIcon icon={faKey} style={{ width: '20px', color: 'gray' }} />
									</button>
									<div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">

									</div>
									<div class="relative w-full">
										<input type="text" name="confirmnewpassword" id="phone-input" onChange={(e) => setConfirmnewpassword(e.target.value)} aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Enter your Password" required style={{ height: '3rem' }} />
									</div>
								</div>
								<button type="submit" class="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
									Update Profile
								</button>


							</form>
						</section>

						<div class="w-full border-t border-gray-200 px-6 flex flex-col lg:flex-row items-center justify-between ">
							<div class="flex flex-col sm:flex-row items-center max-lg:border-b border-gray-200">


							</div>

						</div>

					</div>
					<ToastContainer />
				</div>

			</section>
		</>
	)
}

export default MyProfile