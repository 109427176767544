import React from 'react'
import Working from './assets/working.jpg'
import Logo from './assets/logo.png'
import Avatar from './assets/avatar.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen, faChalkboardUser, faClockRotateLeft, faCubes, faGear, faKey, faSquarePollVertical, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCalendarCheck, faCirclePlay, faHeart, faMessage, faUser,  } from '@fortawesome/free-regular-svg-icons'
import { Player } from '@lottiefiles/react-lottie-player'
import Header from '../Layout/Header'

const AccountSetting = () => {
  return (
    <>
    <Header />
    
    <section>
        <div className="course-timeline-wrap " style={{ marginTop: '15rem' }} />
        

        <a href="#" class="block max-w-[18rem] p-[1.5rem] bg-white border border-gray-200 rounded-[1.3rem] shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700" style={{ marginLeft: '69px', marginTop: '1px', textDecoration: 'none', height: '38rem' }}>
        <Player src='https://lottie.host/896b7ddc-12f7-49fb-a16f-569d522c71ae/qQQTslNxUD.json' autoplay loop style={{height: '90px'}}></Player>
            <h3 className='font-sans md:font-semibold flex justify-center align-middle' style={{ width: '254px', color: 'black', position: 'relative', top: '9px', height: '35px', fontSize: '21px' }}>Rakesh M</h3>
            <span className='font-sans flex justify-center align-middle' style={{ width: '252px', color: 'gray', fontWeight: '500', fontSize: '12px', height: '4px' }}>student@example.com</span>
            <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400 font-sans md:font-semibold flex justify-center flex-col align-middle" style={{ width: '276px', padding: '43px 29px' }}>
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                            <FontAwesomeIcon icon={faBookOpen} style={{ color: 'gray', width: '30px' }} />
                            <a className='font-semibold text-gray-900 dark:text-white' style={{ padding: '10px', textDecoration: 'none' }}>My Course</a>
                        </li>
                        <li class="flex items-center space-x-3 rtl:space-x-reverse">
                            <FontAwesomeIcon icon={faCubes} style={{ color: 'black', width: '30px', width: '30px' }} />
                            <a className='font-semibold text-gray-900 dark:text-white' style={{ padding: '10px', textDecoration: 'none' }}>Course Bundles</a>
                        </li>
                        
                        <li class="flex items-center space-x-3 rtl:space-x-reverse">
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ color: 'black', width: '30px' }} />
                            <a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/BookedTution'>Live Classes</a>
                        </li>
                        
                        
                       
                        <li class="flex items-center space-x-3 rtl:space-x-reverse">
                            <FontAwesomeIcon icon={faClockRotateLeft} style={{ color: 'black', width: '30px' }} />
                            <a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/Payment'>Purchase history</a>
                        </li>
                        <li class="flex items-center space-x-3 rtl:space-x-reverse">
                            <FontAwesomeIcon icon={faUser} style={{ color: 'black', width: '30px' }} />
                            <a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/profile'>Profile</a>
                        </li>
                        <li class="flex items-center space-x-3 rtl:space-x-reverse">
                            <FontAwesomeIcon icon={faKey} style={{ color: 'black', width: '30px' }} />
                            <a class="font-semibold text-gray-900 dark:text-white" style={{ padding: '10px', textDecoration: 'none' }} href='/accountSetting'>Account</a>
                        </li>
            </ul>
        </a>
        <div class="w-full max-w-[75rem]  md:px-5 lg-6 mx-auto relative bottom-[-36px] md:bottom-[697px] pl-[0.5rem] lg:pl-[18.5rem] " >
            <h2 class="font-sans font-semibold text-4xl leading-10 text-black text-center " style={{height: '100px'}}>
                Account Setting
            </h2>
            
            <div class="block bg-white border shadow border-gray-200 rounded-xl pt-6 max-w-xl max-lg:mx-auto lg:max-w-full  h-[48rem] md:h-[40rem]">
                <div
                    class="flex flex-col lg:flex-row lg:items-center justify-between px-6 pb-6 border-b border-gray-200">
                    <div class="data">
                    <Player src='https://lottie.host/896b7ddc-12f7-49fb-a16f-569d522c71ae/qQQTslNxUD.json' autoplay loop style={{height: '90px'}}></Player>
                    </div>
        </div>
        <section>
                    <h4 className='ml-[8.5rem] md:ml-[3rem] mt-[1rem] md:mt-[1rem]'>Account Setting</h4>
                </section>
                <hr className='m-[1rem] '/>
                <form class="max-w-sm mx-auto" style={{width: '49rem'}}>
    <label for="phone-input" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Address:</label>
    <div class="flex items-center mt-2">
        <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{height: '3rem'}}>
            <FontAwesomeIcon icon={faUser} style={{width: '20px'}}/>
        </button>
        <div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
           
        </div>
        <div class="relative w-full">
            <input type="text" id="phone-input" aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Student@mail.com" required  style={{height: '3rem'}}/>
        </div>
    </div>
    <label for="phone-input" class="mt-[1.5rem] text-sm font-medium text-gray-900 dark:text-white">Current password:</label>
    <div class="flex items-center mb-[0rem]">
        <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{height: '3rem'}}>
            <FontAwesomeIcon icon={faKey} style={{width: '20px', color: 'gray'}}/>
        </button>
        <div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
           
        </div>
        <div class="relative w-full">
            <input type="text" id="phone-input" aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Enter your Password" required  style={{height: '3rem'}}/>
        </div>
    </div>
    <label for="phone-input" class="mt-[1.5rem] text-sm font-medium text-gray-900 dark:text-white">New password:</label>
    <div class="flex items-center mb-[0rem]">
        <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{height: '3rem'}}>
            <FontAwesomeIcon icon={faKey} style={{width: '20px', color: 'gray'}}/>
        </button>
        <div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
           
        </div>
        <div class="relative w-full">
            <input type="text" id="phone-input" aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Enter your Password" required  style={{height: '3rem'}}/>
        </div>
    </div>
    <label for="phone-input" class="mt-[1.5rem] text-sm font-medium text-gray-900 dark:text-white">Confirm password:</label>
    <div class="flex items-center mb-[0rem]">
        <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button" style={{height: '3rem'}}>
            <FontAwesomeIcon icon={faKey} style={{width: '20px', color: 'gray'}}/>
        </button>
        <div id="dropdown-phone" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700">
           
        </div>
        <div class="relative w-full">
            <input type="text" id="phone-input" aria-describedby="helper-text-explanation" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Enter your Password" required  style={{height: '3rem'}}/>
        </div>
    </div>
    
    
</form>
<div class="w-full border-t border-gray-200 px-6 flex flex-col lg:flex-row items-center justify-between ">
                    <div class="flex flex-col sm:flex-row items-center max-lg:border-b border-gray-200">
                        
                        
                    </div>
                   
                </div>
        </div>
        </div>
        </section>
        </>
  )
}

export default AccountSetting