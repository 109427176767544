import React from 'react';
import Logo from '../pages/assets/logo2.png';
import { Player } from '@lottiefiles/react-lottie-player';
import Whatsapp from '../pages/assets/whatsapp.png';

const Footer = () => {
  return (
    <footer className="text-center text-surface/75 dark:bg-neutral-700 dark:text-white/75 lg:text-left" style={{ backgroundColor: '#007dc6' }}>
      {/* <Player src='https://lottie.host/1733023c-2d86-4166-b009-2fa96389b25f/Mcb1BvVBGB.json' autoplay loop style={{ height: '300px', position: 'relative', float: 'right' }}></Player> */}

      {/* Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. */}
      <div className="mx-6 pb-10 md:pt-24 text-center md:text-left">
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4" style={{ marginTop: '0rem' }}>
          {/* TW Elements section */}
          <img src={Logo} alt="logo" className="w-32 mx-auto md:mx-0" />
          {/* Products section */}
          <div>
            <h6 className="mb-4 font-semibold uppercase" style={{ color: 'white' }}>For Student </h6>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="/" style={{ color: 'white', textDecoration: 'none' }}>Student Login</a>
            </p>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="#" style={{ color: 'white', textDecoration: 'none' }}>Career In Medicine</a>
            </p>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="#" style={{ color: 'white', textDecoration: 'none' }}>Rules & Requirement</a>
            </p>
            {/* Other links */}
          </div>
          {/* Useful links section */}
          <div>
            <h6 className="mb-4 font-semibold uppercase" style={{ color: 'white' }}>Company</h6>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="/mycourse" style={{ color: 'white', textDecoration: 'none' }}>Courses</a>
            </p>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="/payment" style={{ color: 'white', textDecoration: 'none' }}>Payment History</a>
            </p>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="/profile" style={{ color: 'white', textDecoration: 'none' }}>Profile</a>
            </p>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="/wishlist" style={{ color: 'white', textDecoration: 'none' }}>Wishlist</a>
            </p>
            {/* Other links */}
          </div>
          {/* Contact section */}
          <div>
            <h6 className="mb-4 font-semibold uppercase" style={{ color: 'white' }}>Community</h6>
            <p className="mb-4" style={{ color: 'white' }}>
              <a href="#" style={{ color: 'white', textDecoration: 'none' }}>Partners</a>
            </p>
            {/* Other links */}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 md:flex-row md:justify-between" style={{ margin: '6rem' }}>
        <div className="mb-4 md:mb-0">
          <span className='font-semibold' style={{ color: 'white' }}>Get connected with us on social networks:</span>
        </div>
        {/* Social network icons container */}
        
        <div className="flex justify-center md:justify-end">
       

          <ul className="text-white md:mr-10">
          

            <li className="mb-2">WhatsApp: 07958913329</li>
            <li className="mb-2">Email: info@educatetheworld.uk</li>
          </ul>
          {/* Social network icons */}
          
        </div>
      </div>
      {/* Copyright section */}
      <div className="bg-black/5 p-6 text-center font-semibold" style={{ color: 'white' }}>
        <span>Copyright © 2024 Educate The World || </span>
        <a className="font-semibold" href="#" style={{ color: 'white' }}>
          Powered By Megaminds
        </a>
      </div>
    </footer>
  );
}

export default Footer;
