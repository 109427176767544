import React, { useEffect } from 'react'
import login from '../pages/assets/login.png'
import microsoft from '../pages/assets/microsoft.svg'
import logo from './assets/logo.png'
import { useNavigate } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player'
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
import { API_URL } from '../config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const initGoogleSignIn = () => {
			if (window.google) {
				window.google.accounts.id.initialize({
					client_id: "402486600921-1mq58ak1ieappj3ou05p245q2nb6mgg4.apps.googleusercontent.com",
					callback: handleCredentialResponse
				});

				window.google.accounts.id.renderButton(
					document.getElementById("signInDiv"),
					{ theme: "outline", size: "large" }
				);

				window.google.accounts.id.prompt(); // This will prompt the user to select an account
			} else {
				// Retry initialization if window.google is not defined
				setTimeout(initGoogleSignIn, 100);
			}
		};

		initGoogleSignIn();
	}, []);

	const handleCredentialResponse = (response) => {
		console.log("Encoded JWT ID token: " + response.credential);
		// Assuming response.credential is the correct token; if it's an ID token, you need the auth code instead
		axios.post(`${API_URL}/auth/google`, { token: response.credential })
			.then(response => {
				console.log('Success:', response.data);
				navigate('/dashboard');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const email = event.target.email.value;
		const password = event.target.password.value;
		// Validate email and password fields
		if (!email || !password) {
			toast.error('Email and password are required');
			return;
		}

		// Validate email format
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailPattern.test(email)) {
			toast.error('Invalid email format');
			return;
		}

		// Validate password length
		if (password.length < 6) {
			toast.error('Password must be at least 6 characters long');
			return;
		}

		try {
			const response = await axios.post(`${API_URL}/auth/signin`, { email, password });
			if (response.data.success) {
				localStorage.setItem('token', response.data.token);
				toast.success('Login successful!');
				const redirectCourseId = localStorage.getItem('redirectAfterLogin');
				if (redirectCourseId) {
					localStorage.removeItem('redirectAfterLogin');
					navigate(`/eventDetail/${redirectCourseId}`);
				} else {
					navigate('/dashboard');
				}
			} else {
				toast.error(response.data.message || 'Login failed');
			}
		} catch (error) {
			console.error('Login Error:', error);
			toast.error('Login failed. Please try again.');
		}
	};



	return (
		<div class="flex h-screen">
			<div class="hidden lg:flex items-center justify-center flex-1 bg-white text-black">
				<div class="max-w-full text-center">
					<Player
						autoplay
						src='https://lottie.host/8b003807-7a58-48f4-bc6e-56926ce8d9b3/3hTQx0pjIH.json'
						loop
						style={{
							width: '600px'
						}}
					>
					</Player>
				</div>
			</div>

			<div class="w-full bg-gray-100 lg:w-1/2  flex items-center justify-center h-[59rem]">
				<div class="max-w-md w-full p-6">
					<img src={logo} alt="" style={{
						width: '12%',
						position: 'relative',
						left: '75px',
						top: '51px'
					}} />
					<h1 class="text-3xl font-semibold mb-[3.5rem] text-black text-center">Sign In</h1>
					{/* <h1 class="text-sm font-semibold mb-6 text-gray-500 text-center">Join to Our Community with all time access and free </h1> */}
					{/* <div class="mt-4 flex flex-col lg:flex-row items-center justify-between">
						<div class="w-full lg:w-1/2 ml-[2px] lg:ml-2 mb-[9px]">
							<div id="signInDiv"></div>
						</div>

						<div class="w-full lg:w-1/2 ml-[2px] lg:ml-2 mb-[9px]">
							<button type="button" class="w-full flex justify-center items-center gap-2 bg-white text-sm text-gray-600 p-2 rounded-md hover:bg-gray-50 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition-colors duration-300">
								<img src={microsoft} alt="" width={20} />
								Sign In with Microsoft </button>
						</div>
					</div>
					<div class="mt-4 text-sm text-gray-600 text-center">
						<p>or with email</p>
					</div> */}
					<form onSubmit={handleSubmit} class="space-y-4">
						<div>
							<label for="email" class="block text-sm font-medium text-gray-700">Email</label>
							<input type="text" id="email" name="email" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300" />
						</div>
						<div style={{ marginTop: '3rem' }}>
							<label for="password" class="block text-sm font-medium text-gray-700">Password</label>
							<input type="password" id="password" name="password" class="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300" />
						</div>
						<div>
							<button type="submit" class="w-full bg-blue-900 text-white p-2 rounded-md hover:bg-blue-800 focus:outline-none focus:bg-blue-8000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 transition-colors duration-300" style={{
								position: 'relative',
								top: '21px'
							}}>Sign In

							</button>
						</div>
					</form>
					<div class="mt-4 text-sm text-gray-600 text-center">
						<p>Don't have an account? <a href="signup" class="text-black hover:underline">Sign Up</a>
							<p>Forgot your password? <a href="forgot-password" class="text-black hover:underline">Reset Password</a></p>
						</p>
					</div>
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default Login