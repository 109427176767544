import { useState, Fragment, useEffect } from 'react'
import '../styles/style.css'
import '../styles/bootstrap-4.5.3.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faLock, faPlayCircle, faPlay, faGlobe, faArrowRight, faCheck, faAngleRight, faAnglesRight, faHourglassEnd, faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlay, faFileAlt, faClock, faClipboard, } from '@fortawesome/free-regular-svg-icons'
import { Accordion } from 'react-bootstrap'
import CourseDetails2 from './assets/course-details2.jpg'
import Header from '../Layout/Header';
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { handleNon200Response, checkSession } from './session';
import { API_URL } from '../config'
import SimpleAccordion from '../components/SimpleAccordion'; // Adjust the path as necessary
import { useCart } from './CartContext';



const CourseOverview = () => {
	const [active, setActive] = useState(`collapse1`);
	const [categories, setCategories] = useState([]);
	const [latestCourses, setLatestCourses] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const onClick = (value) => {
		setActive(current => current === value ? "" : value);
	}
	const [course, setCourse] = useState({
		title: '',
		author: '',
		level: '',
		duration: '',
		subject: '',
		lectures: '',
		language: '',
		price: 0,
		discountPrice: 0,
		videoUrl: '',
		description: '',
		requirements: '',
		expiryPeriod: '',
		certificateIncluded: false,
		courseImage: '',
		curriculum: []
	});
	const { id } = useParams();
	useEffect(() => {
		checkSession();
		fetchCourse();
	}, [id]);

	const fetchCourse = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await axios.get(`${API_URL}/api/courses/student/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`

				}
			});
			if (response.status === 200) {
				setCourse(response.data.data);
				console.log(response.data.data);
			} else {
				handleNon200Response(response);
			}
		} catch (err) {
			if (err.response) {
				handleNon200Response(err.response);
			} else {
				toast.error('Unexpected Error!');
			}
		}
	};

	const fetchLatestCourses = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/courses/list/latest`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setLatestCourses(response.data.data);
				//toast.success('Banners fetched successfully!');				
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};
	const getEmbedUrl = (url) => {
		const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
		return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : url;
	};
	const { setCartCount } = useCart();
	const handleAddToCart = async (courseId) => {
		const token = localStorage.getItem('token');


		try {
			const response = await axios.post(`${API_URL}/api/cart/add`, { courseId }, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			});

			if (response.status === 201) {
				toast.success(response.data.message);
				setCartCount(prevCount => prevCount + 1); // Increment cart count
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		}
	};
	useEffect(() => {
		checkSession();
	}, []);


	const fetchCategories = async () => {
		setLoading(true);
		setError(null);
		const token = localStorage.getItem('token');  // Retrieve the token from local storage

		try {
			const response = await axios.get(`${API_URL}/api/categories/list/students`, {
				headers: {
					Authorization: `Bearer ${token}`  // Include the token in the Authorization header
				}
			});
			if (response.status === 200) {
				setCategories(response.data.data);
				//toast.success('Banners fetched successfully!');
			} else {
				handleNon200Response(response);
			}
		} catch (error) {
			if (error.response) {
				handleNon200Response(error.response);
			} else {
				toast.error('Unexpected Error!');
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);
	useEffect(() => {
		fetchLatestCourses();
	}, []);
	const embedUrl = getEmbedUrl(course.videoUrl);
	const navigate = useNavigate()
	return (


		<>

			<Header />
			<section className="course-details-area pt-[176px] rpt-100">

				<div className="container">

					<div className="row large-gap">
						<div className="col-lg-8">
							<div className="course-details-content">
								<ToastContainer />
								<h3 className='font-sans md:font-semibold'>{course.title}</h3>
								<ul className="author-date-enroll">
									<li>
										<i className="fas fa-cloud-upload-alt" /> Last Updated:
										{new Date(course.updatedAt).toLocaleDateString()}
									</li>
									<li>
										<i className="far fa-user" /> 25 Enrolled
									</li>
									<li>
										<i className="far fa-user" /> {course.author}
									</li>
								</ul>
								<div className="image mb-35">
									<img
										src={`${API_URL}/${course.courseImage}`}
										alt="Course Details" />
								</div>
								<p className='font-sans md:font-semibold'>
									{course.description}
								</p>
								<h3 className="mt-40 font-sans md:font-semibold">What Will You Learn?</h3>
								<ul className="list-style-two mb-45">
									<li className='font-sans md:font-semibold'>
										<FontAwesomeIcon icon={faCheck} style={{ color: 'blue' }} width={30} />
										{course.requirements}
									</li>

								</ul>
								<h3 className='font-sans md:font-semibold'>Target Audience</h3>
								<ul className="list-style-two mb-45">
									<li className='font-sans md:font-semibold'>
										<FontAwesomeIcon icon={faCheck} style={{ color: 'blue' }} width={30} />
										Any Job Holders, Students
									</li>
									<li className='font-sans md:font-semibold'>
										<FontAwesomeIcon icon={faCheck} style={{ color: 'blue' }} width={30} />
										Corporate Trainer
									</li>
									<li className='font-sans md:font-semibold'>
										<FontAwesomeIcon icon={faCheck} style={{ color: 'blue' }} width={30} />
										Educators (Teachers, Lecturer, Faculty)
									</li>
								</ul>
								<h3 className='font-sans md:font-semibold'>Course Curriculum {course.title}</h3>
								<Accordion className="faq-accordion pt-10 pb-50 wow fadeInUp delay-0-2s" id="course-faq" defaultActiveKey="collapse1" >
									{course.curriculum.length > 0 ? (
										course.curriculum.map((module) => (
											<SimpleAccordion key={module._id} title={module.title}>
												<ul className="course-video-list">
													{module.contentVideoPairs.map((pair) => (
														<li key={pair._id}>
															<a
																href="#"
																className="mfp-iframe course-video-play"
																style={{ color: '#415674', textDecoration: 'none' }}
															>
																<span className="title">{pair.content}</span>{" "}
																<FontAwesomeIcon icon={faCirclePlay} width={30} />{" "}
																<span className="duration">Duration not specified</span>
															</a>
														</li>
													))}
												</ul>
											</SimpleAccordion>
										))
									) : (
										<p>No curriculum available.</p>
									)}
								</Accordion>

								{/* 								<h3 className='font-sans md:font-semibold'>Student Feedback</h3>
								<div className="student-feedback pt-10 wow fadeInUp delay-0-2s">
									<div className="row">
										<div className="col-sm-5">
											<div className="student-average-feedback bg-green text-center text-white">
												<b>5.0</b>
												<div className="ratting mb-10">
													<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
													<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
													<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
													<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
													<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
												</div>
												<h6>Total 1 Rating</h6>
											</div>
										</div>
										<div className="col-sm-7 align-self-center">
											<div className="student-feedback-details">
												<div className="student-feedback-author mb-20">
													<img
														src={Author}
														alt="Authro" />
													<div className="content">
														<h4>Lucius D. Thomas</h4>
														<span className="designations">
															IT Students (Basic)
														</span>
													</div>
												</div>
												<div className="ratting-total">
													<div className="ratting-total-item">
														<div className="ratting mb-10">
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<span>(5)</span>
														</div>
														<span className="ratting-bar">
															<span style={{ width: "100%" }} />
														</span>
														<span className="ratting-number ">1 Rating</span>
													</div>
													<div className="ratting-total-item">
														<div className="ratting mb-10">
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<span>(4)</span>
														</div>
														<span className="ratting-bar">
															<span style={{ width: 0 }} />
														</span>
														<span className="ratting-number">0 Rating</span>
													</div>
													<div className="ratting-total-item">
														<div className="ratting mb-10">
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<span>(3)</span>
														</div>
														<span className="ratting-bar">
															<span style={{ width: 0 }} />
														</span>
														<span className="ratting-number">0 Rating</span>
													</div>
													<div className="ratting-total-item">
														<div className="ratting mb-10">
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<span>(2)</span>
														</div>
														<span className="ratting-bar">
															<span style={{ width: 0 }} />
														</span>
														<span className="ratting-number">0 Rating</span>
													</div>
													<div className="ratting-total-item">
														<div className="ratting mb-10">
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<FontAwesomeIcon icon={faStar} style={{ color: 'orange' }} width={20} />
															<span>(1)</span>
														</div>
														<span className="ratting-bar">
															<span style={{ width: 0 }} />
														</span>
														<span className="ratting-number">0 Rating</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="course-sidebar rmt-75" style={{ width: "25rem" }}>
								<div className="widget widget-course-details wow fadeInUp delay-0-2s">
									<div className="widget-video">
										<iframe
											width="560"
											height="315"
											src={embedUrl}
											title="YouTube video player"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											referrerPolicy="strict-origin-when-cross-origin"
											allowFullScreen
										></iframe>
									</div>
									<div className="price-off">
										<span className="price">{course.discountPrice}</span>
									</div>
									<ul className="course-details-list mb-25">

										<li>
											<FontAwesomeIcon icon={faClock} style={{ color: 'blue' }} width={30} />
											<span>Duration</span>{" "}
											<b>{course.duration}</b>
										</li>
										<li>
											<FontAwesomeIcon icon={faClipboard} style={{ color: 'blue' }} width={30} />
											<span>Subject</span>{" "}
											<b>{course.subject}</b>
										</li>
										<li>
											<FontAwesomeIcon icon={faGlobe} style={{ color: 'blue' }} width={30} />
											<span>Language</span>{" "}
											<b>{course.language}</b>
										</li>
										<li>
											<FontAwesomeIcon icon={faCertificate} style={{ color: 'blue' }} width={30} />
											<span>Certificate</span>{" "}
											<b>{course.certificateIncluded ? "Included" : "Not Included"}</b>
										</li>
										<li>
											<FontAwesomeIcon icon={faHourglassEnd} style={{ color: 'blue' }} width={30} />
											<span>Expiry Period</span>{" "}
											<b>{course.expiryPeriod}</b>
										</li>
									</ul>

									<div>
										<input type="checkbox" id="termsCheckbox" className="mr-2" onChange={(e) => setTermsAccepted(e.target.checked)} />
										<label htmlFor="termsCheckbox">I accept the <a href="https://events.educatetheworld.co.uk/terms-and-conditions" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></label>
									</div>
									<a className="theme-btn" onClick={() => {
										if (termsAccepted) {
											handleAddToCart(course._id);
										} else {
											toast.error('Please accept the terms and conditions to proceed.');
										}
									}}>
										add to cart
										<FontAwesomeIcon icon={faArrowRight} style={{ color: 'white' }} width={30} />
									</a>

								</div>
								<div className="widget widget-menu wow fadeInUp delay-0-2s">
									<h4 className="widget-title font-sans md:font-semibold">Category</h4>
									<ul>
										{categories.map(category => (
											<li key={category._id} className='font-sans md:font-semibold' style={{ color: 'gray' }}>
												<FontAwesomeIcon icon={faAnglesRight} style={{ color: 'gray' }} width={30} />
												<a href="#">{category.title}</a>
											</li>
										))}
									</ul>
								</div>
								{/* <div className="widget widget-recent-courses wow fadeInUp delay-0-2s">
									<h4 className="widget-title font-sans md:font-semibold">Recent Courses</h4>
									<ul>
										<li>
											<div className="image">
												<img
													src={Course1}
													alt="Course" />
											</div>
											<div className="content">
												<h6 className='font-sans md:font-semibold'>

													How to Learn Basic Web (UI) Design

												</h6>
												<span style={{ color: 'gray' }}>
													By <a className='font-sans md:font-semibold' href="#" style={{ color: 'gray' }}>Somalia</a>
												</span>
											</div>
										</li>
										<li>
											<div className="image">
												<img
													src={Course2}
													alt="Course" />
											</div>
											<div className="content">
												<h6 className='font-sans md:font-semibold'>

													How to Learn Basic Web Development

												</h6>
												<span style={{ color: 'gray' }}>
													By <a className='font-sans md:font-semibold' href="#" style={{ color: 'gray' }}>Somalia</a>
												</span>
											</div>
										</li>
										<li>
											<div className="image">
												<img
													src={Course3}
													alt="Course" />
											</div>
											<div className="content">
												<h6 className='font-sans md:font-semibold'>
													<a href="#" >
														How to Learn Basic (SEO) Marketing
													</a>
												</h6>
												<span style={{ color: 'gray' }}>
													By <a className='font-sans md:font-semibold' href="#" style={{ color: 'gray' }}>Blanchard</a>
												</span>
											</div>
										</li>
										<li>
											<div className="image">
												<img
													src={Course4}
													alt="Course" />
											</div>
											<div className="content">
												<h6 className='font-sans md:font-semibold'>
													<a href="#">
														Business Strategy Managements
													</a>
												</h6>
												<span style={{ color: 'gray' }} >
													By <a className='font-sans md:font-semibold' style={{ color: 'gray' }} href="#">Johnson</a>
												</span>
											</div>
										</li>
									</ul>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="coach-section-two rel z-1 pt-120 rpt-90 pb-130 rpb-100">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-lg-7 col-md-8">
							<div className="section-title text-center mb-45">
								<h2 className=" font-sans " style={{ color: 'blue' }}>Latest Courses</h2>
								<h3 className='font-sans md:font-semibold'>Browser Latest Courses</h3>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:mx-40 lg:mt-4 gap-4">

						{/* <!-- card 1 --> */}
						{latestCourses.map(course => (
							<div key={course._id} class="p-[0.5rem] max-w-sm">
								<div class="block max-w-sm p-0 bg-white border border-gray-200 rounded-[1.3rem] shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
									<div class="flex items-center mb-3">
										<img src={`${API_URL}/${course.courseImage}`} alt={course.title} style={{ borderRadius: '7px', cursor: 'pointer' }} onClick={() => navigate(`/course/${course._id}`)} />
									</div>
									<div class="flex flex-col justify-between flex-grow" style={{
										padding: '10px 30px',
										height: '114px'
									}}>
										<p class="leading-relaxed text-base text-black dark:text-black font-sans md:font-semibold">
											{course.title}
										</p>
										<span className='font-sans md:font-semibold' style={{ color: 'gray', fontSize: '13px' }}>By {course.author}</span>
									</div>
									<h5 className='font-sans md:font-semibold' style={{ height: '49px', padding: '17px 33px' }}>
										<strike>£{course.price}</strike> £{course.discountPrice}
									</h5>
								</div>
							</div>
						))}
					</div>

				</div>
			</section>

		</>


	)
}

export default CourseOverview